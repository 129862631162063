<template>
  <div class="detail-box w">
    <h3 class="titlefont15">{{ album.albumName }}</h3>
    <div class="oneline">
      <!-- <div class="taglist">
        <div class="tag-item tag_1"></div>
        <div class="tag-item tag_1"></div>
        <div class="tag-item tag_1"></div>
        <div class="tag-item tag_2"></div>
        <div class="tag-item tag_3"></div>
      </div> -->
      <el-rate
        v-model="album.score"
        disabled
        :colors="['#FADB14', '#FADB14', '#FADB14']"
        disabled-void-color="#DDDDDD"
        score-template="{album.score}"
      >
      </el-rate>
      <div class="join">{{ album.favorNum }}人已观看</div>
    </div>
    <div class="vediobox">
      <div class="vediocontent">
        <!-- <div class="prism-player" id="J_prismPlayer"></div> -->
        <Video
          :encryptType="1"
          :vid="actVideoId"
          :classId="actClassId"
          :albumId="album.id"
          :playauth="playAuth"
          ref="player"
          :useFlashPrism="false"
          :autoplay="true"
          :isLive="false"
          :showBuffer="true"
          :cover="cover"
          :disableSeek="true"
          showBarTime="500"
          width="100%"
          height="100%"
          controlBarVisibility="always"
          :isVideo="isVideo"
        ></Video>
      </div>
      <div class="vedionavlist">
        <div class="vedionav">
          <div class="navbox">
            <h4 class="titlefont13">
              课程目录<span class="beizhufont1"
                >（{{ activeindex }}/{{ classList.length }}）</span
              >
            </h4>
            <img
              @click="isShowNav = !isShowNav"
              v-if="isShowNav"
              class="isupdownpdf"
              src="./images/down.png"
              alt=""
            />
            <img
              @click="isShowNav = true"
              v-else
              class="isupdownpdf"
              src="./images/up.png"
              alt=""
            />
          </div>
          <ul v-show="isShowNav">
            <li
              :class="
                actClassId == classes.id ? 'active titlefont2' : 'titlefont2'
              "
              v-for="(classes, index) in classList"
              :key="classes.id"
              @click="changeClass(classes.videoId, classes.id, index)"
            >
              <p>{{ classes.className }}</p>
              <span>{{ classes.newduration }}分钟</span>
            </li>
            <!-- <li class="titlefont2">
              <p>1.第一部分-选词填空</p>
              <span>21分钟</span>
            </li>
            <li class="titlefont2">
              <p>1.第一部分-选词填空</p>
              <span>21分钟</span>
            </li>
            <li class="titlefont2">
              <p>1.第一部分-选词填空</p>
              <span>21分钟</span>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="introducebox">
      <div class="titlebox"><p class="titlefont1">简介</p></div>
      <div class="introduce">
        <p v-html="album.remark"></p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  classList, updateFavor
} from "@/api"
import Video from "../../components/Video"
export default {
  name: "FindDetail",
  components: {
    Video
  },
  data () {
    return {
      input3: '',
      // id: 0,
      // albumName: '',
      album: {},//专辑
      classQuery: {
        albumId: 0,
        albumName: '',
        pageNum: 1,
        pageSize: 1000,
      },
      classList: [],
      classTotal: 0,
      activeindex: 1,
      baseUrl: window.__baseUrl__,

      cover: '', //视频封面
      // vid: '', //视频vid
      playAuth: '', //鉴权地址

      actVideoId: '',
      actClassId: '',
      isVideo: "Y",
      isShowNav: true,
    }
  },
  mounted () {
    this.album = this.$route.params.albums
    this.classQuery.albumId = this.album.id
    this.classQuery.albumName = this.album.albumName
    // this.classQuery.albumId = this.$route.query.albumId
    // this.classQuery.albumName = this.$route.query.albumName
    this.getClassList()
    this.updateFavorBySel()
  },
  methods: {
    updateFavorBySel () {
      updateFavor({ albumId: this.classQuery.albumId })
    },
    changeClass (vid, cid, index) {
      this.actVideoId = vid
      this.actClassId = cid
      this.activeindex = index + 1
    },
    getClassList () {

      classList(this.classQuery).then((d) => {
        if (d.code == 200) {
          console.log(d, 'ddd')
          this.classList = d.rows
          this.actClassId = d.defaultPlayInfo.classId
          this.classList.forEach((item) => {
            item.pdfSize = Math.round(item.pdfSize * 100 / 1024 / 1024) / 100
            item.newduration = Math.round(item.duration / 60)
            if (this.actClassId == item.id) {
              this.actVideoId = item.videoId
              this.actClassId = item.id
              this.isVideo = item.isVideo
            }
          })
          this.classTotal = d.total
          // this.getVideoPlayAuth(this.actVideoId, this.actClassId)

        }

      })

    },


  }
}
</script>

<style lang="less" scoped>
@import url(../../assets/css/common.css);
/deep/.el-rate__icon {
  font-size: 24px;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #eee;
}
/*定义滑块 样式*/
::-webkit-scrollbar-thumb {
  height: 50px; /* 滚动条滑块长度 */
  background-color: #ccc;
}
.navbox {
  display: flex;
  justify-content: space-between;
}
.isupdownpdf {
  // display: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.detail-box {
  padding: 130px 0 80px;
  .oneline {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .taglist {
    display: flex;

    .tag-item {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
    .tag_1 {
      background: url("./images/star1.png") no-repeat;
    }
    .tag_2 {
      background: url("./images/star2.png") no-repeat;
    }
    .tag_3 {
      background: url("./images/star3.png") no-repeat;
    }
  }
  .join {
    cursor: pointer;

    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(119, 119, 119, 1);
  }

  .vediobox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    .vediocontent {
      // width: 997px;
      width: 66.6%;
      height: 562px;
      background: rgba(150, 150, 150, 1);
    }
    .vedionavlist {
      // width: 493px;
      width: 33%;
      height: 562px;
      // overflow: auto;

      .vedionav,
      .pdf {
        // width: 493px;
        // width: 100%;
        height: 100%;
        overflow-y: auto;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.6);
        border: 2px solid rgba(255, 255, 255, 1);
        backdrop-filter: blur(20px);
        padding: 20px 25px;

        // margin-top: 10px;
        a {
          text-decoration-line: underline;
        }
      }
      ul {
        margin-top: 10px;
        li {
          display: flex;
          justify-content: space-between;
          height: 36px;
          line-height: 36px;
          margin-top: 5px;
          padding: 0 8px;
          cursor: pointer;
          span {
            color: rgba(170, 170, 170, 1);
          }
          p {
            display: flex;
            align-items: center;
            .pdficon {
              display: block;
              width: 24px;
              height: 24px;
              background: url("./images/pdf.png");
              margin-right: 4px;
            }
          }
        }
        .active {
          height: 36px;
          border-radius: 4px;
          background: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .titlebox {
    height: 21px;
    border-left: 3px solid rgba(67, 207, 124, 1);
    line-height: 21px;
    padding-left: 7px;
    margin-top: 30px;
  }
  .introducebox {
    .introduce {
      margin: 16px 0 26px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      line-height: 1.5;
    }
  }
  .courselist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .courseitem {
      width: 284px;
      height: 250px;
      // margin-right: 20px;
      margin: 16px 0 50px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(20px);
      border: 2px solid rgba(255, 255, 255, 1);
      position: relative;
      img {
        width: 284px;
        height: 160px;
        position: absolute;
        top: -2px;
        left: -2px;
      }
      // .coursedes {

      //   border-top: none;
      // }
      .collect {
        position: absolute;
        right: 16px;
        top: 125px;
        height: 24px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.5);
        padding: 1px 9px 1px 9px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
      }
      .oneline {
        margin-top: 170px;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        .taglist {
          display: flex;

          .tag-item {
            width: 40px;
            height: 24px;
            border-radius: 4px;
            margin-right: 10px;

            font-size: 14px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            line-height: 24px;
          }
          .tag_four {
            background: linear-gradient(
              135deg,
              rgba(255, 138, 20, 1) 0%,
              rgba(254, 197, 101, 1) 100%
            );
          }
          .tag_new {
            background: linear-gradient(
              90deg,
              rgba(76, 224, 215, 1) 0%,
              rgba(81, 159, 232, 1) 100%
            );
          }
          .tag_vip {
            background: linear-gradient(
              90deg,
              rgba(224, 76, 76, 1) 0%,
              rgba(232, 161, 82, 1) 100%
            );
          }
          .tag_free {
            background: linear-gradient(
              90deg,
              rgba(224, 76, 76, 1) 0%,
              rgba(232, 161, 82, 1) 100%
            );
          }
        }
        .studynum {
          font-size: 16px;
          font-weight: 400;
          color: rgba(170, 170, 170, 1);
        }
      }
      .twoline {
        font-size: 18px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        margin-top: 14px;
        padding: 0 16px;
      }
    }
  }
}
@media (max-width: 1116px) {
  .vediocontent {
    width: 100% !important;
  }
  .vedionavlist {
    width: 100% !important;
  }
  .courseitem {
    width: 240px !important;
    height: 211px !important;
    img {
      width: 240px !important;
      height: 135px !important;
    }
  }
  .detail-box .courselist .courseitem .oneline {
    margin-top: 140px !important;
  }
  .collect {
    top: 100px !important;
  }
  .vedionav {
    margin-top: 20px !important;
  }
}
</style>